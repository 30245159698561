var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{staticClass:"pathModul",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{class:_vm.$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-select',{attrs:{"items":_vm.itemStation,"item-text":"stationdisplay","item-value":"stationvalue","label":"Station","prepend-inner-icon":"mdi-store-marker","hide-details":"","solo":"","dense":""},on:{"change":_vm.selectValueStation},model:{value:(_vm.stnSelect),callback:function ($$v) {_vm.stnSelect=$$v},expression:"stnSelect"}})],1),_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prefix":"From :","prepend-inner-icon":"mdi-calendar-start","readonly":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prefix":"To :","prepend-inner-icon":"mdi-calendar-end","readonly":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar2),callback:function ($$v) {_vm.modalCalendar2=$$v},expression:"modalCalendar2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.modalCalendar2 = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(this.$store.getters.user.role == 5)?_c('v-btn',{staticClass:"white--text button mt-3 mr-2",attrs:{"solo":"","dense":"","normal":""},on:{"click":_vm.generateTable}},[_vm._v(" GENERATE REPORT ")]):_c('v-btn',{staticClass:"white--text button mt-3 mr-2",attrs:{"solo":"","dense":"","normal":"","disabled":_vm.valStation==null},on:{"click":_vm.generateTable}},[_vm._v(" GENERATE REPORT ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showExportBtn),expression:"showExportBtn"}],staticClass:"mt-3",staticStyle:{"float":"right"},attrs:{"color":"excel","elevation":"2"},on:{"click":function($event){return _vm.exportExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showExportBtn),expression:"showExportBtn"}],staticClass:"mt-3 mx-2",staticStyle:{"float":"right"},attrs:{"color":"csv","elevation":"2"},on:{"click":function($event){return _vm.exportCSV()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-file-delimited ")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-subheader',{staticClass:"subTitle black--text font-weight-bold pa-0"},[_c('center',[_vm._v(" Raw Data For ("+_vm._s(_vm.valStation)+") From "+_vm._s(this.convertDateFormat(_vm.dateFrom))+" To "+_vm._s(this.convertDateFormat(_vm.dateTo)))])],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1 tableRawData headerDtSarawak headerDesktop",attrs:{"headers":_vm.thead_rawData,"items":_vm.tbody_rawData,"items-per-page":_vm.itemPerPage,"fixed-header":"","loading":_vm.loadingTable,"loading-text":_vm.loadingText,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.DATETIME",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.DATETIME)}})]}},{key:"item.WQI",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.getFontColor(item.WQI),attrs:{"color":_vm.getColor(item.WQI),"dark":""}},[_vm._v(" "+_vm._s(item.WQI)+" ")])]}},{key:"item.pH",fn:function(ref){
var item = ref.item;
return [( item.pH < 5.5 || item.pH > 9)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.pH))]):_c('span',[_vm._v(_vm._s(item.pH))])]}},{key:"item.NO3",fn:function(ref){
var item = ref.item;
return [( item.NO3 > 10)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NO3))]):_c('span',[_vm._v(_vm._s(item.NO3))])]}},{key:"item.TDS",fn:function(ref){
var item = ref.item;
return [( item.TDS > 1500)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TDS))]):_c('span',[_vm._v(_vm._s(item.TDS))])]}},{key:"item.TURBIDITY",fn:function(ref){
var item = ref.item;
return [( item.TURBIDITY > 1000)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TURBIDITY))]):_c('span',[_vm._v(_vm._s(item.TURBIDITY))])]}}],null,true),model:{value:(_vm.tableRaw),callback:function ($$v) {_vm.tableRaw=$$v},expression:"tableRaw"}}),_c('p',{staticStyle:{"font-size":"0.90em","text-align":"left"}},[_c('b',[_vm._v(" Note: Red text indicated that value has exceeded above maximum & minimum value ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }